import Login from '../pages/auth/Login';
// import Register from '../pages/auth/Register';
// import ResetPass from '../pages/auth/ResetPass';
import Dashboard from '../pages/dashboard/Index';
import Deposit from '../pages/dashboard/deposit';

import {Routes} from './route_helper';

import NotFound from '../pages/NotFound';
import Withdraws from '../pages/dashboard/withdraws';
import XbetMedias from '../components/1xbetmedias';
import MelbetMedias from '../components/melbetmedias';
import Android from '../pages/Android';


const authProtectedRoutes = [
  {path: Routes.dashboard, component: Dashboard},
  {path: Routes.deposits, component: Deposit},
  {path: Routes.withdraws, component: Withdraws},
  
];

const initialRoute = [{path: '*', component: NotFound}];
const publicRoutes = [
  {path: Routes.login, component: Login},
  {path: Routes.xbet, component: XbetMedias},
  {path: Routes.melbet, component: MelbetMedias},
  {path: Routes.android, component: Android},
  {path: Routes.mobile, component: Android},
  // {path: Routes.forgot, component: ResetPass},
];




export {
  publicRoutes,
  initialRoute,
  authProtectedRoutes,
};
