import { useContext, useEffect, useState } from 'react';
import { ColumnsType } from 'antd/es/table';
import { useNavigate, useParams } from 'react-router-dom';
import { CopyOutlined } from '@ant-design/icons';

import DataTable from '../../components/table/Index';
import { useDoc, useCollectionWithMulti, useCollection } from '../../helpers/firestore';
import { DepositModel } from '../../types';
import { Button, Spin, Col, Row, message, Alert, Tag, Divider, Space } from 'antd';

import { UserContext } from '../../context/app.contexts';
import { checkKhanBank, manageDeposit } from '../../helpers/firebase_functions'
// import XbetMedias from '../../components/1xbetmedias';
// import MelbetMedias from '../../components/melbetmedias';


const Deposits = () => {
  const checkSecs = 20
  const [messageApi, contextHolder] = message.useMessage();
  const { id, type, accountID } = useParams()
  const { user } = useContext(UserContext);

  const [checkMsg, setCheckMsg] = useState('');
  const [retryDepo, setRetryDepo] = useState(false);
  const [isCheckDepo, setIsCheckDepo] = useState(false);
  const [checkTime, setCheckTime] = useState(checkSecs);
  const [bank, bankLoading] = useDoc('configs/wazDepositBank');
  const [config, configLoading] = useDoc(`wazsConfig/${id}`);
  const [deposits, depositsLoading] = useCollectionWithMulti(`deposits`, 'createdAt', { field: 'userID', op: '==', value: user.uid }, { field: 'depAcc', op: '==', value: id }, 10);
  const limitDesc = `Та эрхээ нэмэхийн тулд https://www.facebook.com/mnmelbet/ хаяг руу чат бичээрэй  Таны ID: ${accountID} `

  const checkDeposit = async () => {
    if (isCheckDepo) {
      messageApi.error(`${checkTime} секунтын дараа дахин шалгана уу`)
      return
    }
    setCheckTime(checkSecs)
    setIsCheckDepo(true)

    const result = await checkKhanBank(id || '');
    setCheckMsg(result.mgs)
    if (result.status) {
      messageApi.info(result.msg)
    } else {
      messageApi.error(result.msg)
    }
  }
  useEffect(() => {
    let timer: any;

    if (isCheckDepo && checkTime > 0)
      timer = setInterval(() => {
        setCheckTime((prevSec) => prevSec - 1)
      }, 1000);
    else if (checkTime === 0) {
      console.log(checkTime)
      setIsCheckDepo(false)
    }
    return () => clearInterval(timer)
  }, [isCheckDepo, checkTime])

  const clipboardwriteText = (value: string) => {
    navigator.clipboard.writeText(value);
    messageApi.info('Амжилттай хуулагдлаа!')
  }
  const onClickDetail = async (depoID: string) => {
    console.log(depoID)
    if (retryDepo) {
      messageApi.info('Таны хүсэлтийг шалгаж байна. Та түр хүлээнэ үү')
      return
    }
    setRetryDepo(true)

    messageApi.info('Таны хүсэлтийг шалгаж байна. Та түр хүлээнэ үү')
    const result = await manageDeposit(depoID, 1);
    console.log(result)

    if (result.status) {
      messageApi.info(result.msg)
    } else {
      messageApi.error(result.msg)
    }
    setRetryDepo(false)


  };

  const columns: ColumnsType<DepositModel> = [

    {
      key: 'amount',
      title: 'Цэнэглэлт дүн',
      dataIndex: 'amount',
      width: 225,
      render: (amount: any) => { return numberWithCommas(amount.amount) }
    },
    {
      key: 'statusMsg',
      title: 'Төлөв',
      dataIndex: 'statusMsg',
      render: (statusMsg: string, record) => {
        if (record.status == 'new') {
          return <Tag color="processing"> {statusMsg}</Tag>
        }
        if (record.status == 'review') {
          return <Tag color="warning"> {statusMsg}</Tag>
        }
        if (record.status == 'Done') {
          return <Tag color="success"> {statusMsg}</Tag>
        }
        if (record.status == 'MelError') {
          return (
            <>

              <Tag color="error" > {statusMsg}</Tag>
              {(record.status == 'MelError' || record.status == 'review') && (

                <Button
                  size="small"
                  type="primary"
                  className="bg-success mt-1"
                  loading={retryDepo}
                  onClick={() => {
                    onClickDetail(record.uid);
                  }}>Дахин оролдох</Button>
              )}
            </>)
        }
      }
    },

    {
      key: 'lvl',
      title: 'LVL',
      dataIndex: 'lvl',
      render: (lvl: Number) => { return (<>{`+ ${lvl}`}</>) }
    },
    {
      key: 'transactionDate',
      title: 'Гүйлгээ огноо',
      dataIndex: 'transactionDate',
      width: 125,
      render: (transactionDate: string) => transactionDate.slice(0, 10),
    },
    {
      key: 'txnTime',
      title: 'цаг',
      dataIndex: 'txnTime',
    },

  ];
  function numberWithCommas(x: any) {
    if (x)
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  return (
    <div>
      {contextHolder}
      <Row gutter={[16, 16]}>
        <Col xs={22} lg={12}>
          {bank && bank.is_active && (<>
            <div className="card-container px-5 py-7">
              {config && (<>
                <div className="mt-5 text-lg text-black">Таны LVL</div>
                <div className="mt-1" >
                  <h2 >⭐️ {config.lvl}</h2>
                </div>
              </>
              )}

              <Spin spinning={bankLoading && bank.is_active}>
                <>
                  <div className="flex justify-between items-center mb-2">
                    <div className="text-lg font-medium">
                      ЦЭНЭГЛЭХ ДАНСНЫ МЭДЭЭЛЭЛ
                    </div>

                  </div>
                  <div className="mt-5 text-lg text-gray">Банк</div>

                  <div className="mt-1" onClick={() => { clipboardwriteText((bank as any).name) }}>
                    <h2> {(bank as any).name} <CopyOutlined /></h2>
                  </div>
                  <div className="mt-5 text-lg text-gray">Дансны дугаар</div>
                  <div className="mt-1" onClick={() => { clipboardwriteText((bank as any).accountNumber) }}>
                    <h2> {(bank as any).accountNumber} <CopyOutlined /></h2>
                  </div>
                  <div className="mt-5 text-lg text-gray">Дансны нэр</div>
                  <div className="mt-1" onClick={() => { clipboardwriteText((bank as any).accountName) }}>
                    <h2> {(bank as any).accountName} <CopyOutlined /></h2>
                  </div>
                  <div className="mt-5 text-lg text-gray">
                    Гүйлгээний утга
                  </div>
                  <div className="mt-1" onClick={() => { clipboardwriteText(`${accountID}`) }}>
                    <h2>{accountID}<CopyOutlined /></h2>
                  </div>
                </>
                {/* <Alert message="🎉 Promo code 🎉 Промо код 🎉" description={ 
                  <>
                    {type == '1xbet' && (
                      <div className="mt-1" onClick={() => { clipboardwriteText(`${xbetPromo}`) }}>
                        <h2>{xbetPromo} <CopyOutlined /></h2>
                      </div>

                    )}
                    {type == 'melbet' && (
                      <div className="mt-1" onClick={() => { clipboardwriteText(`${melbetPromo}`) }}>
                        <h2>{melbetPromo} <CopyOutlined /></h2>
                      </div>
                    )}
                  </>
                } type="success" showIcon />
                 */}
                <Button className="w-full rounded-xl my-2" type='primary' onClick={checkDeposit} loading={isCheckDepo} disabled={isCheckDepo}>
                  {checkTime > 0 && isCheckDepo && (<>{checkTime} {' sec '}</>)}
                  Төлбөр шалгах
                </Button>


                <Alert description={<>
                  Цэнэг доод тал нь 900₮ <br />
                  5 минутын дотор ижил дүнгээр цэнэглэж болохгүй. Таны гүйлгээ хүлээгдэх болно   </>} type="warning" showIcon />
                <Alert description="
              ТА МӨНГӨӨ ШИЛЖҮҮЛЭЭД ДООРХ
ТӨЛБӨР ШАЛГАХ ТОВЧИН ДЭЭР ДАРНА УУ  " type="warning" showIcon />
                {checkMsg && (
                  <h5>{checkMsg}</h5>
                )}


              </Spin>



            </div>
          </>)}

          {bank && !bankLoading && !bank.is_active && (<>
            <div className="card-container px-5 py-7">
              <Alert message="Анхааруулга" description="
              Засвартай байгаа тул та дараа дахин оролдоно уу" type="warning" showIcon />
            </div>
          </>
          )}
        </Col>
      </Row>
 
      <DataTable
        tableName="Цэнэглэлтийн түүx"
        columns={columns}
        data={deposits}
        loading={depositsLoading}

      />

    </div>);
};

export default Deposits;
