import { useEffect } from 'react';
import { Button,  } from 'antd';


const Android = () => {

  useEffect(() => {
    window.location.href = 'https://probet.mn/probetmn.apk';
  }, []);

  return (
    <div className="download-page">
        <h1 className="page-title">Аpp татах</h1>
        <p className="page-description">
         Илүү хурдан бас хялбар боллоо. 
        </p>

        {/* Google Play Button */}
        <div  >
              <div  >
                <Button
                  type="primary"
                  size="large"
                  className="download-button android-button"
                  onClick={() => window.location.href = 'https://probet.mn/probetmn.apk'}
                >
                  Android татах
                </Button> 
              </div>
          
        </div>

    </div>
  );
};

export default Android;