import {Menu, MenuProps} from 'antd';
import React, {useContext, useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {useLocation} from 'react-router-dom';
import {UserContext} from '../../context/app.contexts';
import {Routes} from '../../routes/route_helper';
import {AuthRoles} from '../../types';

type MenuItem = Required<MenuProps>['items'][number];
const adminMenuData = [
  {
    name: 'Нүүр',
    path: Routes.dashboard,
  },

  {
    name: 'Апп татах',
    path: Routes.mobile,
  },

];



const SidebarContent = () => {
  const location = useLocation();
  const {userRole} = useContext(UserContext);
  const [menuItems, setMenuItems] = useState([] as MenuItem[]);
  const [rootSubmenuKeys, setRootSubMenuKeys] = useState([] as string[]);
  const [openKeys, setOpenKeys] = useState([] as string[]);
  const [openGroupKeys, setOpenGroupKeys] = useState([] as string[]);

  function getItem(
    label: React.ReactNode,
    key: React.Key,
    icon?: React.ReactNode,
    children?: MenuItem[],
    type?: 'group',
  ): MenuItem {
    return {
      key,
      icon,
      children,
      label,
      type,
    } as MenuItem;
  }

  useEffect(() => {
    let data = [] as any[];
      data = adminMenuData;
    setMenuItems(
      data.map((m) =>
        getItem(
          m.children != null ? m.name : <Link to={m.path}>{m.name}</Link>,
          m.path,
          null,
          m.children?.map((c: any) =>
            getItem(<Link to={c.path}>{c.name}</Link>, c.path),
          ),
        ),
      ),
    );
    setRootSubMenuKeys(
      data.filter((m) => m.children != null).map((m) => m.path),
    );
  }, [userRole]);

  const onOpenChange: MenuProps['onOpenChange'] = (keys) => {
    const latestOpenKey = keys.length > 0 ? keys[keys.length - 1] : '';
    if (rootSubmenuKeys.indexOf(latestOpenKey!) === -1) {
      setOpenGroupKeys(keys);
    } else {
      setOpenGroupKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  useEffect(() => {
    setOpenKeys([location.pathname.toString()]);
    menuItems.forEach((m: any) => {
      var children = m.children?.map((c: any) => c.key);
      if (children?.includes(location.pathname)) {
        setOpenGroupKeys([m.key]);
      }
    });
  }, [location.pathname, menuItems]);

  return (
    <Menu
      mode="inline"
      selectedKeys={openKeys}
      openKeys={openGroupKeys}
      onOpenChange={onOpenChange}
      style={{width: 250}}
      items={menuItems}
    />
  );
};

export default SidebarContent;
